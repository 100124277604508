import React from "react";
import Layout from "../../components/Layout";
import { ImageConstants } from "../../constants/Imagconstants";
import { workSlider } from "../../constants/swiperConstants";
import { Swiper, SwiperSlide } from "swiper/react";

const SupportMaintainanceServices = () => {
  return (
    <Layout
      title={
        "Software support and maintenance service in Luxembourg | Razrtech"
      }
    >
      <main>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="subpageheader">
                  <h1>Support & Maintenance Services</h1>
                  <p>
                    From trouble-shooting to maintenance services for your
                    software and IT infrastructure, our expert IT field
                    engineers take care of all your IT needs, so you can focus
                    on the things that matter the most.
                  </p>
                  <a
                    href="https://form.jotform.com/222352612041340"
                    target="_blank"
                    className="btn btn-primary mt-5"
                  >
                    Get Started
                  </a>
                  <div className="hire-bg-1">
                    <img
                      loading="lazy"
                      src={ImageConstants.remoteBg1}
                      alt="design"
                    />
                  </div>
                  <div className="hire-bg-2">
                    <img
                      loading="lazy"
                      src={ImageConstants.remoteBg2}
                      alt="design"
                    />
                  </div>
                  <div className="hire-bg-3">
                    <img
                      loading="lazy"
                      src={ImageConstants.remoteBg3}
                      alt="design"
                    />
                  </div>
                  <div className="hire-bg-4">
                    <img
                      loading="lazy"
                      src={ImageConstants.remoteBg4}
                      alt="design"
                    />
                  </div>
                  <div className="hire-bg-5">
                    <img
                      loading="lazy"
                      src={ImageConstants.remoteBg5}
                      alt="design"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="abt-banner">
                  <figure>
                    <img
                      loading="lazy"
                      src={ImageConstants.remoteBanner}
                      alt="hire-banner"
                    />
                  </figure>
                </div>
                <div className="sub-width-60 mt-5">
                  <p className="commanp mt-3">
                    Our service support desk gives you round-the-clock access to
                    our dedicated technical team – online or over the phone. The
                    support team is accountable for proactively keeping your
                    services fast, efficient and up-to-date.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="commantitel">Our Support Services Include:</h2>
              </div>
            </div>
          </div>
          <Swiper className="workslider" {...workSlider}>
            <div className="swiper-wrapper">
              <SwiperSlide>
                <div className="work-main  work-main-yellow  border-radius-left-top outsourseslider top-align-slider">
                  <div>
                    <figure className="all-slider-img">
                      <img
                        loading="lazy"
                        src={ImageConstants.complianceLogo}
                        alt="cyber-slider"
                      />
                    </figure>
                    <h3>Compliance</h3>
                    <p className="commanp">
                      Get to the root cause of your software or infrastructure
                      issues.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              {/* <SwiperSlide>
                <div className="work-main work-main-pink  outsourseslider top-align-slider">
                  <div>
                    {" "}
                    <figure className="all-slider-img">
                      <img
                        loading="lazy"
                        src={ImageConstants.modificationIcon}
                        alt="cyber-slider"
                      />
                    </figure>
                    <h3>
                      Modifications & <br /> Tailored IT <br /> Solutions
                    </h3>
                    <p className="commanp">
                      We are proud on our top notch services. Our services comes
                      with following benefits:
                    </p>
                  </div>
                </div>
              </SwiperSlide> */}
              <SwiperSlide>
                <div className="work-main  work-main-sky  outsourseslider top-align-slider">
                  <div>
                    <figure className="all-slider-img">
                      <img
                        loading="lazy"
                        src={ImageConstants.infrIcon}
                        alt="cyber-slider"
                      />
                    </figure>
                    <h3>Infrastructure Monitoring</h3>
                    <p className="commanp">
                      Management of hardware, servers, data centers, and more.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="work-main work-main-ppt outsourseslider top-align-slider border-radius-right-bottom">
                  <div>
                    <figure className="all-slider-img">
                      <img
                        loading="lazy"
                        src={ImageConstants.cyberSecurityIcon}
                        alt="cyber-slider"
                      />
                    </figure>
                    <h3>Cyber Security</h3>
                    <p className="commanp">Patches, breaches, guidance.</p>
                  </div>
                </div>
              </SwiperSlide>
            </div>
            <div className="swiper-button-next">
              <img loading="lazy" src={ImageConstants.nextArrow} alt="next" />
            </div>
            <div className="swiper-button-prev">
              <img loading="lazy" src={ImageConstants.preArrow} alt="Prev" />
            </div>
          </Swiper>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="sub-width-60">
                  <h2 className="commantitel">
                    Our Software Development Support Includes
                  </h2>
                  <div className="key-box mt-3 remote">
                    <span className="skybox">Service Delivery Management</span>
                    <span className="yellow">Code Level Support</span>
                    <span className="lightgreenbox">
                      Cloud Subscription Management
                    </span>
                    <span className="darkpinkbox">Dedicated DevOps</span>
                    <span className="yellow">Application Management</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="weServe">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="sub-width-60">
                  <h2 className="commantitel">Industries We Serve</h2>
                </div>
              </div>
            </div>
          </div>
          <Swiper className="workslider" {...workSlider}>
            <div className="swiper-wrapper">
              <SwiperSlide>
                <div className="weservebox">
                  <figure>
                    <img
                      loading="lazy"
                      src={ImageConstants.weServe1}
                      alt="We slider"
                    />
                  </figure>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="weservebox">
                  <figure>
                    <img
                      loading="lazy"
                      src={ImageConstants.weServe2}
                      alt="We slider"
                    />
                  </figure>
                </div>
              </SwiperSlide>
              {/* <SwiperSlide>
                <div className="weservebox">
                  <figure>
                    <img
                      loading="lazy"
                      src={ImageConstants.weServe3}
                      alt="We slider"
                    />
                  </figure>
                </div>
              </SwiperSlide> */}
              <SwiperSlide>
                <div className="weservebox">
                  <figure>
                    <img
                      loading="lazy"
                      src={ImageConstants.weServe4}
                      alt="We slider"
                    />
                  </figure>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="weservebox">
                  <figure>
                    <img
                      loading="lazy"
                      src={ImageConstants.weServe5}
                      alt="We slider"
                    />
                  </figure>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="weservebox">
                  <figure>
                    <img
                      loading="lazy"
                      src={ImageConstants.weServe6}
                      alt="We slider"
                    />
                  </figure>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="weservebox">
                  <figure>
                    <img
                      loading="lazy"
                      src={ImageConstants.weServe7}
                      alt="We slider"
                    />
                  </figure>
                </div>
              </SwiperSlide>
            </div>
            <div className="swiper-button-next">
              <img loading="lazy" src={ImageConstants.nextArrow} alt="next" />
            </div>
            <div className="swiper-button-prev">
              <img loading="lazy" src={ImageConstants.preArrow} alt="Prev" />
            </div>
          </Swiper>
        </section>
        <section>
          <div className="container">
            <div className="letstalksection">
              <div className="row align-items-center">
                <div className="col-lg-9 col-md-8">
                  <div className="talktext">
                    <h2 className="commantitel">
                      Let us take care of your tech so you can focus on things
                      that matter the most!
                    </h2>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="text-center">
                    <a
                      href="https://form.jotform.com/222352612041340"
                      target="_blank"
                      className="btn btn-primary"
                    >
                      Get in touch
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default SupportMaintainanceServices;
